@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./quilljs.scss";

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 0.25rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

body {
  @apply bg-slate-100;
}

input[type="text"] {
  @apply px-2 h-8 leading-8 w-full;
}

.link {
  @apply underline text-blue-800 hover:text-blue-600 cursor-pointer;
}

.c-input {
  @apply px-2 h-8 leading-8 w-full bg-white border border-zinc-300 rounded-md;
}

.c-btn {
  @apply px-4 bg-white font-semibold hover:bg-zinc-100 hover:shadow-sm rounded-md text-sm leading-6;
}

.c-btn-secondary {
  @extend .c-btn;
  @apply border border-zinc-200;
}

.c-btn-primary {
  @extend .c-btn;
  @apply bg-blue-200 hover:bg-blue-100;
}

.c-btn-w-icon {
  @extend .c-btn;
  @apply flex pl-2 py-1 items-center justify-start;

  svg {
    @apply mr-2;
  }
}

.c-icon-btn {
  @apply flex items-center justify-center w-6 h-6 rounded-sm hover:bg-gray-200;
}

.c-card-btn {
  @apply hover:bg-white/60;
}

/* card color */

.card-white {
  @apply bg-white;

  .c-card-btn {
    @apply hover:bg-gray-100;
  }

  &.card--board .card__btn {
    @apply bg-slate-200;
  }

  .ql-editor code, .ql-editor pre.ql-syntax {
    @apply bg-slate-100;
  }
}

.card-red {
  @apply bg-red-200;

  .ql-editor {
    @apply focus:ring-red-600/40;
  }

  .ql-blank::before {
    @apply text-red-800/60;
  }

  &.card--board .card__btn {
    @apply bg-red-300;
  }

  .ql-editor code, .ql-editor pre.ql-syntax {
    @apply bg-red-300;
  }
}

.card-blue {
  @apply bg-blue-200;

  .ql-editor {
    @apply focus:ring-blue-600/40;
  }

  .ql-blank::before {
    @apply text-blue-800/60;
  }

  &.card--board .card__btn {
    @apply bg-blue-300;
  }

  .ql-editor code, .ql-editor pre.ql-syntax {
    @apply bg-blue-300;
  }
}

.card-yellow {
  @apply bg-amber-100;

  .ql-editor {
    @apply focus:ring-amber-600/40;
  }

  .ql-blank::before {
    @apply text-amber-800/60;
  }

  &.card--board .card__btn {
    @apply bg-amber-300;
  }

  .ql-editor code, .ql-editor pre.ql-syntax {
    @apply bg-amber-200;
  }
}

.card-green {
  @apply bg-emerald-200;

  .ql-editor {
    @apply focus:ring-emerald-600/40;
  }

  .ql-blank::before {
    @apply text-emerald-800/60;
  }

  &.card--board .card__btn {
    @apply bg-emerald-300;
  }

  .ql-editor code, .ql-editor pre.ql-syntax {
    @apply bg-emerald-300;
  }
}

.card-purple {
  @apply bg-violet-200;

  .ql-editor {
    @apply focus:ring-violet-600/40;
  }

  .ql-blank::before {
    @apply text-violet-800/60;
  }

  &.card--board .card__btn {
    @apply bg-violet-300;
  }

  .ql-editor code, .ql-editor pre.ql-syntax {
    @apply bg-violet-300;
  }
}